import CssBaseline from '@material-ui/core/CssBaseline';

import React from 'react';
import { matchPath, Redirect, useLocation } from 'react-router-dom';
import config from 'routes/config';
import { Main } from './styles';

function LoggedLayout(props) {
  const { children } = props;

  let location = useLocation();

  const activeRoute = Object.keys(config)
    .map(key => config[key])
    .flat()
    .find(route => matchPath(location.pathname, route));

  if (!activeRoute) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <CssBaseline />
      <Main
        useBg={
          location.pathname.includes('/login') ||
          location.pathname.includes('/obrigado')
        }
      >
        {children}
      </Main>
    </>
  );
}

export default LoggedLayout;
