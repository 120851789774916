import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: '#fff',
    backgroundColor: '#25D366',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#25D366',
    },
  },
}));

function WhatsappButton(props) {
  const classes = useStyles();

  return (
    <Fab className={classes.fab} color="primary" aria-label="add" {...props}>
      <a
        href="https://wa.me/5541987997875?text=Hola Bruna, necesito ayuda para hacer la prueba Hecho para DAF."
        target="_blank"
        rel="noreferrer"
        style={{ fontWeight: 'bold' }}
      >
        <WhatsAppIcon />
      </a>
    </Fab>
  );
}

export default WhatsappButton;
