import React, { useState } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

function PasswordToggle({ value = '' }) {
  const [show, setShow] = useState(false);

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs="auto">
        <Typography>{show ? value : '•'.repeat(value.length)}</Typography>
      </Grid>
      <Grid item xs="auto">
        <IconButton
          onClick={() => setShow(prevState => !prevState)}
          size="small"
        >
          {show ? (
            <VisibilityOff style={{ color: '#000' }} />
          ) : (
            <Visibility fontSize="small" style={{ color: '#000' }} />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default PasswordToggle;
