/* eslint-disable no-prototype-builtins */
import React, { useEffect } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import LoginForm from 'forms/LoginForm';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import DAF from 'images/DAF-branco.svg';
import Centered from 'components/Centered/Centered';
import WhatsappButton from 'components/WhatsappButton/WhatsappButton';

function Login() {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const history = useHistory();
  const { user, signIn } = useAuth();

  useEffect(() => {
    if (user.hasOwnProperty('novaSenha') && user.novaSenha == false) {
      history.push('/nova-senha');
    }

    if (user.hasOwnProperty('novaSenha') && user.novaSenha == true) {
      history.push('/');
    }
  }, [user]);

  const onSubmit = async values => {
    try {
      await signIn(values);
    } catch (error) {
      toast.error(
        'Não foi possível realizar o login, confira as credenciais informadas.',
      );
    }
  };

  if (isMobile) {
    return (
      <Box
        style={{ height: '100%', backgroundColor: '#014a94', color: '#fff' }}
      >
        <Centered column>
          <Box mb={7} mt={7} textAlign={'center'}>
            <img
              style={{ width: '100%', maxWidth: '288px' }}
              src={DAF}
              alt="Logo da DAF"
            />
          </Box>
          <Typography
            style={{
              fontSize: '1.5rem',
              textAlign: 'justify',
              marginBottom: '2rem',
            }}
          >
            Prueba no disponible para acceso a través de dispositivo móvil,
            según reglamento:
          </Typography>
          <Typography
            style={{
              fontSize: '1.5rem',
              textAlign: 'justify',
              marginBottom: '2rem',
            }}
          >
            &quot;El técnico deberá acceder a las pruebas a través de un
            ordenador (fijo o portátil). No podrá ser a través de dispositivos
            móviles (como tabletas y/o celulares).&quot;
          </Typography>
          <Typography
            style={{
              fontSize: '1.5rem',
              textAlign: 'justify',
              marginBottom: '2rem',
            }}
          >
            <a
              href="https://firebasestorage.googleapis.com/v0/b/questionario-daf-es.appspot.com/o/20230719%20-%20V.Final%20-%20Premio%20Te%CC%81cnico%20del%20An%CC%83o%20DAF%202023%20Espan%CC%83ol%20MS%20REV5%20(002).pdf?alt=media&token=9a9431d2-bd6c-4bd4-ba2b-8f0e391bf18c"
              target="_blank"
              rel="noreferrer"
              style={{ fontWeight: 'bold' }}
            >
              Clic aquí
            </a>{' '}
            y lea el reglamento completo.
          </Typography>
        </Centered>
      </Box>
    );
  }

  return (
    <Box style={{ height: '100%' }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={6}
        >
          <Box mt={30}>
            <LoginForm onSubmit={onSubmit} />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          style={{
            textAlign: 'center',
          }}
        ></Grid>
      </Grid>
      <WhatsappButton />
    </Box>
  );
}

export default Login;
