import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Montserrat"',
  },
  overrides: {
    MUIDataTableSearch: {
      searchText: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 16,
      },
    },
  },
  palette: {
    primary: {
      main: '#014A94',
      dark: '#014A94', // hover
      contrastText: '#000',
    },
    error: {
      main: '#EC1D37',
    },
  },
});

export default theme;
