import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import ProfileForm from 'forms/ProfileForm';
import { FormContainer } from 'styles/global';
import { useAuth } from 'contexts/auth';
import { Container } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core';
import whiteTheme from 'config/whiteTheme';
import { auth, firestore } from 'services/firebase';

function Profile() {
  const { user, getUser } = useAuth();

  useEffect(() => {
    getUser();
  }, []);

  const onSubmit = async (values, form) => {
    document.activeElement.blur();
    if (values.name && form.getState().dirtyFields.name) {
      await firestore.doc(`users/${user.uid}`).update({ name: values.name });
      toast.success('Nome alterado com sucesso!');
    }
    if (values.password) {
      await auth.sendPasswordResetEmail(user.email);
      toast.success('E-mail para troca de senha enviado com sucesso!');
    }
    const nextUser = await getUser();

    setTimeout(
      form.restart({
        name: nextUser.name,
      }),
    );
  };

  return (
    <MuiThemeProvider theme={whiteTheme}>
      <Container>
        <FormContainer>
          <ProfileForm initialValues={user} onSubmit={onSubmit} />
        </FormContainer>
      </Container>
    </MuiThemeProvider>
  );
}

export default Profile;
