import styled, { css } from 'styled-components';

export const Main = styled.main`
  min-height: 100%;
  background-color: #fff;
  flex-grow: 1;
  ${props => props.useBg && `background-image: url('bg.png'); padding-top: 0;`}
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  color: #000;
  margin: 0 0 0 25px;
  padding: 0;

  a,
  button {
    display: flex;
    align-items: center;
    height: 100px;
    padding: 0 15px;
    font-size: 16px;
    border-top: 1px solid transparent;
  }
`;

export const ListItem = styled.li`
  ${props =>
    props.selected &&
    css`
      a {
        border-top: 1px solid #000;
      }
    `}
`;
