import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { firebase, firestore } from 'services/firebase';
import { deleteDocuments } from 'utils/firebase';

function ReiniciarTentativas({ id = '' }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toastId = useRef(null);

  async function handleReiniciarTentativas() {
    try {
      setLoading(true);
      toastId.current = toast('Reiniciando tentativas...', {
        autoClose: false,
      });
      const respostas = await firestore
        .collection('respostas')
        .where('usuario', '==', id)
        .get();
      toast.update(toastId.current, { render: 'Buscando por respostas...' });
      await deleteDocuments({
        collectionName: 'respostas',
        docIds: respostas.docs.map(item => item.id),
      });
      await firestore.doc(`users/${id}`).update({
        tentativa: 0,
        dataInicioProva: firebase.firestore.FieldValue.delete(),
        dataFimProva: firebase.firestore.FieldValue.delete(),
        quantidadeAcertos: firebase.firestore.FieldValue.delete(),
      });

      toast.update(toastId.current, {
        render: 'Tentativas reiniciadas com sucesso!',
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
      });
      setOpen(false);
    } catch (error) {
      toast.update(toastId.current, {
        render: 'Erro ao reiniciar tentativas',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        style={{ textDecoration: 'underline', color: 'blue' }}
        color="primary"
      >
        Reiniciar tentativas
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">Reiniciar tentativas</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Essa ação é irreversível. Deseja realmente reiniciar as tentativas?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button
            disabled={loading}
            onClick={handleReiniciarTentativas}
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ReiniciarTentativas;
