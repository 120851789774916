import Button from '@material-ui/core/Button';
import createDecorator from 'final-form-focus';
import { TextField } from 'final-form-material-ui';
import { Field, Form } from 'react-final-form';
import { Person } from '@material-ui/icons';
import { Box, Grid, IconButton, InputAdornment, Link } from '@material-ui/core';
import { validate } from 'utils/validate';
import { schema } from './schema';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';

const focusOnErrors = createDecorator();

function ForgotPasswordForm({ onSubmit }) {
  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      validate={validate(schema)}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Field
            name="email"
            type="text"
            component={TextField}
            label="E-mail"
            margin="normal"
            fullWidth
            variant="outlined"
            style={{ color: 'white !important' }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ backgroundColor: 'white' }}
                >
                  <IconButton>
                    <Person />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Grid item xs={12}>
            <Box color="#fff">
              <InfoIcon style={{ marginRight: '0.2rem' }} />
              <Link align="right" component={RouterLink} to="/login">
                Retornar para login
              </Link>
            </Box>
          </Grid>

          <Box mt={3}>
            <Button
              disabled={submitting}
              variant="contained"
              color="primary"
              type="submit"
              style={{
                float: 'right',
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#154B85',
              }}
            >
              ENVIAR
            </Button>
          </Box>
        </form>
      )}
    />
  );
}

export default ForgotPasswordForm;
