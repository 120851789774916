import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'contexts/auth';

function PrivateRoute({ children, admin, ...rest }) {
  const { user, userLoading, signed } = useAuth();

  const hasPermission = admin && !userLoading ? user.admin : true;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        signed && hasPermission ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
