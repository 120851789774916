import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Montserrat"',
  },
  overrides: {
    MUIDataTableSearch: {
      searchText: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 16,
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#fff',
        },
      },
      input: {
        color: '#fff',
      },
      notchedOutline: {
        borderColor: '#fff',
      },
    },
    MuiFormControlLabel: {
      root: {
        color: '#fff',
        '& .MuiSvgIcon-root': {
          fill: '#fff',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#fff',
      },
    },
    MuiFab: {
      primary: {
        backgroundColor: 'transparent',
        border: '1px solid #fff',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      root: {
        boxShadow: 'none',
      },
    },
    MuiSelect: {
      icon: {
        color: '#fff',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
  props: {
    MuiFab: {
      disableRipple: true,
    },
  },
  palette: {
    primary: {
      main: '#fff',
      dark: '#fff', // hover
      contrastText: '#014A94',
    },
    secondary: {
      main: '#000',
      dark: '#000', // hover
      contrastText: '#fff',
    },
    error: {
      main: '#EC1D37',
    },
  },
});

export default theme;
