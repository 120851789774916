import { setupYup } from 'config/yup';

const Yup = setupYup();

export const schema = Yup.object().shape({
  password: Yup.string()
    .min(8)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'A senha deve conter 8 caracteres, pelo menos um caractere maiúsculo, um caractere minúsculo, um número, e um caractere especial.',
    ),
  newPassword: Yup.string().test(
    'passwords-match',
    'Senhas devem ser iguais',
    function(value) {
      return this.parent.password === value;
    },
  ),
});
