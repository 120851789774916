/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from 'services/firebase';
import { DataGrid } from '@material-ui/data-grid';
import PasswordToggle from 'components/PasswordToggle';
import ReiniciarTentativas from 'components/ReiniciarTentativas/ReiniciarTentativas';
import TextField from '@material-ui/core/TextField';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useAuth } from 'contexts/auth';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import XLSX from 'xlsx';

const columns = [
  {
    field: 'nome',
    headerName: 'Nome',
    width: 150,
  },
  {
    field: 'email',
    headerName: 'E-mail',
    width: 300,
  },
  {
    field: 'senha',
    headerName: 'Senha',
    renderCell: ({ value }) => <PasswordToggle value={value} />,
    width: 200,
  },
  {
    field: 'cargo',
    headerName: 'Cargo',
    width: 150,
  },
  {
    field: 'cargo',
    headerName: 'Cargo',
    width: 150,
  },
  {
    field: 'dataInicioProva',
    headerName: 'Início da prova',
    renderCell: ({ row }) => {
      if (!row.dataInicioProva) {
        return '⏱️ Não iniciada';
      }
      return format(row.dataInicioProva.toDate(), 'Pp', { locale: ptBR });
    },
    width: 200,
  },
  {
    field: 'dataFimProva',
    headerName: 'Finalização da prova',
    renderCell: ({ row }) => {
      if (!row.dataFimProva) {
        return '❌ Não finalizada';
      }
      return format(row.dataFimProva.toDate(), 'Pp', { locale: ptBR });
    },
    width: 200,
  },
  {
    field: 'quantidadeAcertos',
    headerName: 'Qtd. acertos',
    renderCell: ({ value }) => value ?? '-',
    width: 150,
  },
  {
    field: 'porcentagemAcertos',
    headerName: '% de acertos',
    width: 150,
    renderCell: ({ row }) => {
      if (!row.quantidadeAcertos) {
        return '-';
      }
      return `${Math.round((row.quantidadeAcertos / 25) * 100)}%`;
    },
  },
  {
    field: 'acoes',
    headerName: 'Ações',
    renderCell: ({ id }) => {
      return <ReiniciarTentativas id={id} />;
    },
    width: 150,
  },
];

function Usuarios() {
  const { user, userLoading } = useAuth();
  const [participantesData = [], loading, error] = useCollectionData(
    firestore.collection('users'),
    {
      idField: 'id',
    },
  );
  const [respostas = [], loadingRespostas, errorRespostas] = useCollectionData(
    firestore.collection('respostas'),
    {
      idField: 'id',
    },
  );
  const [perguntas = [], loadingPerguntas, errorPerguntas] = useCollectionData(
    firestore.collection('perguntas'),
    {
      idField: 'id',
    },
  );
  const [nome, setNome] = useState('');
  const [cargo, setCargo] = useState('');
  // const [openImport, setOpenImport] = useState(false);

  const usuarios = participantesData
    .filter(item => {
      if (!nome) {
        return item;
      }
      return item.nome.toLowerCase().includes(nome.toLowerCase());
    })
    .filter(item => {
      if (!cargo) {
        return item;
      }
      return item.codCargo === cargo;
    });

  if (error || errorRespostas || errorPerguntas) {
    return (
      <Container>
        Ocorreu um erro ao carregar os dados — tente novamente mais tarde!'
      </Container>
    );
  }

  if (loading || userLoading || loadingRespostas || loadingPerguntas) {
    return <Container>Carregando...</Container>;
  }

  if (user.role !== 'ADMIN') {
    return <Redirect to="/" />;
  }

  // const sleep = ms => {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // };

  // const onSubmitImport = async values => {
  //   if (!values.file) return;

  //   Papa.parse(values.file, {
  //     complete: async function(results) {
  //       const objs = results.data.map(function(user) {
  //         const [
  //           email,
  //           senha,
  //           nome,
  //           codCargo,
  //           cargo,
  //           concessionaria,
  //           grupo,
  //           novaSenha,
  //           regulamento,
  //           tentativa,
  //           senhaGerada,
  //         ] = user;
  //         return {
  //           email,
  //           senha,
  //           nome,
  //           codCargo,
  //           cargo,
  //           concessionaria,
  //           grupo,
  //           novaSenha,
  //           regulamento,
  //           tentativa,
  //           senhaGerada,
  //         };
  //       });

  //       for (let user of objs) {
  //         await sleep(2000);

  //         try {
  //           const userCredential = await auth.createUserWithEmailAndPassword(
  //             user.email,
  //             user.senha,
  //           );
  //           const uid = userCredential.user.uid;

  //           await firestore
  //             .collection('users')
  //             .doc(uid)
  //             .set({
  //               name: user.nome,
  //               email: user.email,
  //               senha: user.senha,
  //               nome: user.nome,
  //               codCargo: user.codCargo,
  //               cargo: user.cargo,
  //               concessionaria: user.concessionaria,
  //               grupo: user.grupo,
  //               novaSenha: false,
  //               regulamento: false,
  //               tentativa: 0,
  //               senhaGerada: user.senhaGerada,
  //             });

  //           console.log(`Usuário ${user.email} criado com sucesso.`);
  //         } catch (error) {
  //           console.error(`Erro ao criar usuário ${user.email}: `, error);
  //         }
  //       }
  //     },
  //   });
  // };

  const exportaParticipacoes = () => {
    const participantes = participantesData.filter(item => {
      if (item.quantidadeAcertos) {
        return item;
      }
    });
    const questoes = perguntas.map(
      pergunta => pergunta.images?.join('') + pergunta.questao,
    );

    // Criar o cabeçalho do CSV
    const header = [
      'nomeParticipante',
      'cargo',
      'quantidadeAcertos',
      ...questoes,
    ];

    // Criar os dados para o CSV
    const data = participantes.map(participante => {
      // Filtrar respostas do participante atual
      const respostasDoParticipante = respostas.filter(
        resposta => resposta.usuario === participante.id,
      );

      // Mapear as respostas para um objeto com a questão como chave
      const respostasMap = respostasDoParticipante.reduce((acc, curr) => {
        acc[curr.images?.join('') + curr.pergunta] = curr.resposta;
        return acc;
      }, {});

      // Criar a linha de dados para o participante
      const linha = [
        participante.nome,
        participante.cargo,
        participante.quantidadeAcertos,
      ];

      // Adicionar as respostas nas colunas corretas
      questoes.forEach(questao => {
        linha.push(respostasMap[questao] || '');
      });

      // console.log(linha);
      return linha;
    });

    // Adicionar o cabeçalho aos dados
    data.unshift(header);

    // Converter os dados para um worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Criar um novo workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Respostas');

    // Escrever o workbook para um arquivo CSV
    XLSX.writeFile(workbook, 'respostas-es.csv');
  };

  return (
    <Container maxWidth="xl">
      <Box mt={5}>
        <Typography variant="h4" component="h1" gutterBottom>
          Usuários
        </Typography>
        <Box p={2} border="1px solid rgba(0,0,0,.2)" borderRadius={4}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs="auto" md={3}>
              <TextField
                fullWidth
                onChange={event => setNome(event.target.value)}
                value={nome}
                name="nome"
                placeholder="Pesquise por nome"
              />
            </Grid>
            <Grid item xs="auto" md={3}>
              <FormControl fullWidth>
                <Select
                  value={cargo}
                  displayEmpty
                  onChange={event => setCargo(event.target.value)}
                >
                  <MenuItem value="">
                    <Typography style={{ color: 'rgba(0,0,0,.4)' }}>
                      Selecione um cargo
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'tecnico'}>Técnico</MenuItem>
                  <MenuItem value={'chefeOficina'}>Chefe de oficina</MenuItem>
                  <MenuItem value={'entregador'}>Entregador</MenuItem>
                  <MenuItem value={'instrutor'}>Instrutor</MenuItem>
                  <MenuItem value={'garantista'}>Garantista</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box></Box>
        <div style={{ height: 1000, width: '100%', marginTop: 24 }}>
          <DataGrid
            rows={usuarios}
            columns={columns}
            pageSize={50}
            disableSelectionOnClick
            rowsPerPageOptions={[50]}
          />
        </div>
        <Box onClick={() => exportaParticipacoes()}>Exportar participações</Box>
      </Box>
      {/* <UserImportDialog
        open={openImport}
        onClose={() => {
          setOpenImport(false);
        }}
        onImport={() => {
          setOpenImport(true);
        }}
        onSubmit={onSubmitImport}
      /> */}
    </Container>
  );
}

export default Usuarios;
