import styled, { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.min.css';

const GlobalStyle = createGlobalStyle`

  html, body, #root {
    height: 100%;
    margin: 0;
  }

  html {
    word-break: normal;
  }

  #root {
    min-height: 100%;
    background-image: url('bg.png');
    background-size: cover;
  }

  body {
    background-color: #fff;
    font-family: "Montserrat";
  }

  .MuiFormControl-marginNormal {
    margin-top: 10px;
  }

  .firebase-emulator-warning {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    font-family: "Montserrat";
    padding: 0;
    color: inherit;
    background: none;
    border: none;
    cursor: pointer;
  }

  button:disabled {
    background-color: #a3a3a3 !important;
  }

  .stream-materials{
    color: white !important;
    padding-left: 0 !important;
    a {
      padding-left: 0 !important;
    }
  }

  #menu-cabecalho {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 1rem 3rem;
    margin: 0 auto;
  }

  #menu-cabecalho:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 55px;
    transform: translateY(-50%);
    z-index: 1;
    background-color: #014a94;
  }

  .font-weight-600 {
    font-weight: 600 !important;
  }

  .text-center {
    text-align: center;
  }

  .resposta-correta{
    border: 3px solid green;
    border-radius: 10px
  }

  .resposta-errada{
    border: 3px solid red;
    border-radius: 10px
  }

  .MuiIconButton-label{
    color: white;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
    transition: background-color 5000s;
    -webkit-text-fill-color: #fff !important;
  }

  .btn-vermelho, .btn-vermelho:hover {
    background-color: #EE0000;
  }

  .new-password-field .MuiOutlinedInput-notchedOutline, .new-password-field input, .new-password-field .MuiInputLabel-root{
    border-color: #154B85 !important;
    color: #154B85 !important;
  }

  .color-azul {
    color: #014a94 !important;
  }

  .fill-azul svg {
    fill: #014a94 !important;
  }
`;

export const FormContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

export default GlobalStyle;
