import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const functions = firebase.functions();
const database = firebase.database();

const EMULATORS_STARTED = 'EMULATORS_STARTED';

if (process.env.NODE_ENV === 'development' && !global[EMULATORS_STARTED]) {
  console.log('testing locally -- hitting local auth and firestore emulators');
  // global[EMULATORS_STARTED] = true;
  // auth.useEmulator('http://localhost:9099/');
  // firestore.useEmulator('localhost', 8080);
  // functions.useEmulator('localhost', 5001);
  // database.useEmulator('localhost', 9000);
}

export { firebase, auth, firestore, functions, database };
