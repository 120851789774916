import AssessmentIcon from '@material-ui/icons/Assessment';
// Pages
import Login from 'pages/Login';
import EsqueciSenha from 'pages/EsqueciSenha';
import Profile from 'pages/Profile';
import React, { lazy } from 'react';
import Usuarios from 'pages/Usuarios';

// Logged Pages
const Dashboard = lazy(() => import('pages/Dashboard'));
const NovaSenha = lazy(() => import('pages/NovaSenha'));
const Questionario = lazy(() => import('pages/Questionario'));
const Gabarito = lazy(() => import('pages/Gabarito'));
const Obrigado = lazy(() => import('pages/Obrigado'));

const config = {
  public: [
    {
      path: '/login',
      component: Login,
      exact: true,
    },
    {
      path: '/esqueci-senha',
      component: EsqueciSenha,
      exact: true,
    },
  ],
  protected: [
    {
      path: '/',
      component: Dashboard,
      title: 'Início',
      exact: true,
      icon: <AssessmentIcon />,
    },
    {
      path: '/nova-senha',
      component: NovaSenha,
      title: 'Início',
      exact: true,
      icon: <AssessmentIcon />,
    },
    {
      path: '/profile',
      component: Profile,
      title: 'Profile',
      hideFromMenu: true,
      exact: true,
      icon: <AssessmentIcon />,
    },
    {
      path: '/questionario',
      component: Questionario,
      exact: true,
      icon: <AssessmentIcon />,
    },
    {
      path: '/gabarito',
      component: Gabarito,
      exact: true,
      icon: <AssessmentIcon />,
    },
    {
      path: '/obrigado',
      component: Obrigado,
      exact: true,
      icon: <AssessmentIcon />,
    },
    {
      path: '/admin/usuarios',
      component: Usuarios,
      hideFromMenu: true,
      exact: true,
      icon: <AssessmentIcon />,
    },
  ],
};

export default config;
