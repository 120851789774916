import { firestore } from 'services/firebase';

export const deleteDocuments = async ({ collectionName, docIds }) => {
  const batch = firestore.batch();

  docIds.forEach(id => {
    const docRef = firestore.collection(collectionName).doc(id);
    batch.delete(docRef);
  });

  try {
    await batch.commit();
  } catch (error) {
    console.error('Error deleting documents: ', error);
  }
};
